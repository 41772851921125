import { __awaiter } from "tslib";
import { getAuth, setAuth } from './auth';
import { ApiClientError } from './ApiClientError';
const clockDriftAllowance = 30000;
export class AuthenticatedFetcher {
    constructor(baseUrl, version) {
        this.baseUrl = baseUrl;
        this.version = version;
        this.refreshExpiresAt = 0;
        this.timer = null;
    }
    request(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.baseUrl + request.url;
            const init = {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'X-Version': this.version,
                },
                body: JSON.stringify(request.body),
            };
            if (!request.noAuth) {
                const token = yield this.getAccessToken();
                if (token) {
                    init.headers['Authorization'] = `Bearer ${token}`;
                }
            }
            const response = yield fetch(url, init);
            const result = yield response.json();
            const { error } = result;
            if (error) {
                if (error.status === 401 && !request.blockRetry) {
                    const auth = getAuth();
                    yield this.refreshAuth(auth.refreshToken);
                    return this.request(Object.assign(Object.assign({}, request), { blockRetry: true }));
                }
                else {
                    throw new ApiClientError(error);
                }
            }
            return result;
        });
    }
    getAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const auth = getAuth();
            if (auth && auth.refreshExpiresAt !== this.refreshExpiresAt) {
                this.refreshExpiresAt = auth.refreshExpiresAt;
                this.setTimer();
            }
            if ((!auth.accessToken ||
                auth.expiresAt - clockDriftAllowance < Date.now()) &&
                auth.refreshToken) {
                return this.refreshAuth(auth.refreshToken);
            }
            return auth.accessToken || null;
        });
    }
    refreshAuth(refreshToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(`${this.baseUrl}/v2/refreshAccessToken`, {
                    method: 'post',
                    body: JSON.stringify({
                        refreshToken,
                    }),
                    headers: {
                        'Content-type': 'application/json',
                    },
                });
                const { data } = yield response.json();
                if (data && !data.error) {
                    this.refreshExpiresAt = setAuth(data);
                    this.setTimer();
                    return data.accessToken;
                }
            }
            catch (err) {
                console.log(err);
            }
            const event = new Event('logout');
            window.dispatchEvent(event);
            return null;
        });
    }
    setTimer() {
        if (this.timer)
            clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const event = new Event('logout');
            window.dispatchEvent(event);
        }, this.refreshExpiresAt - Date.now());
    }
}

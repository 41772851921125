import { useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useClient } from './useClient';
export function useFetchState(action, params) {
    const client = useClient();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(undefined);
    useDeepCompareEffectNoCheck(() => {
        if (!params || !client)
            return;
        const subscriber = client.subscribe(action, params, (result, loading) => {
            if (result !== undefined) {
                setState(result);
            }
            if (loading !== undefined) {
                setLoading(loading);
            }
        });
        return () => {
            client.unsubscribe(subscriber);
        };
    }, [params]);
    return [state, setState, loading];
}

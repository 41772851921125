export const typeConverters = new Map();
export function registerTypeConverter(name, converter) {
    if (typeConverters.has(name)) {
        throw new Error(`Type converter ${name} has already been registered`);
    }
    typeConverters.set(name, converter);
}
export function getTypeConverter(name) {
    const converter = typeConverters.get(name);
    return (converter || {
        parse: (value) => value,
        format: (value) => value,
    });
}

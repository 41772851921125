import { __awaiter } from "tslib";
import { v4 as uuid } from 'uuid';
import { defer } from '../util/defer';
import { AuthenticatedFetcher } from './AuthenticatedFetcher';
import { clone, difference, intersection, keyBy, uniq } from 'lodash';
import { ApiClientError } from './ApiClientError';
import ReconnectingWebsocket from 'reconnecting-websocket';
const CACHE_SIZE = 1000;
export class ApiClient {
    constructor(options) {
        this.requestQueue = new Map();
        this.cache = new Map();
        this.subscribers = new Set();
        this.channelSubscribers = new Set();
        this.channels = [];
        this.subscribeToChannels = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.ws || this.ws.readyState !== this.ws.OPEN) {
                return;
            }
            const token = yield this.fetch.getAccessToken();
            const channels = uniq(Array.from(this.channelSubscribers).map((x) => x.channel));
            if (channels.length !== this.channels.length ||
                intersection(channels, this.channels).length !== this.channels.length) {
                this.channels = channels;
                this.ws.send(JSON.stringify({
                    action: 'subscriptions',
                    data: { token, channels },
                }));
            }
        });
        this.onChannelMessage = (e) => {
            const data = JSON.parse(e.data);
            for (const subscriber of this.channelSubscribers) {
                if (subscriber.channel === data.channel) {
                    subscriber.callback(data);
                }
            }
        };
        this.processQueue = () => __awaiter(this, void 0, void 0, function* () {
            this.timerHandle = null;
            const requests = Array.from(this.requestQueue.values());
            this.requestQueue = new Map();
            if (requests.length === 1) {
                const { action, params, deferred } = requests[0];
                this.fetch
                    .request({
                    url: `/v2/${action.name}`,
                    body: params,
                })
                    .then(deferred.resolve, deferred.reject);
            }
            else {
                try {
                    const results = yield this.fetch.request({
                        url: '/v2/multiplex/' + requests.map((x) => x.action.name).join(','),
                        body: {
                            requests: requests.map((request) => {
                                return {
                                    id: request.id,
                                    action: request.action.name,
                                    params: request.params,
                                };
                            }),
                        },
                    });
                    const requestsById = keyBy(requests, (x) => x.id);
                    results.data.forEach((result) => {
                        const queued = requestsById[result.id];
                        queued === null || queued === void 0 ? void 0 : queued.deferred.resolve(result);
                    });
                }
                catch (err) {
                    requests.forEach((request) => request.deferred.reject(err));
                }
            }
        });
        this.fetch = new AuthenticatedFetcher(options.apiUrl, options.version);
        if (options.socketApiUrl) {
            this.ws = new ReconnectingWebsocket(options.socketApiUrl, undefined, {
                connectionTimeout: 60000,
            });
            this.ws.onopen = () => {
                this.channels = [];
                this.subscribeToChannels();
            };
            this.ws.onmessage = this.onChannelMessage;
        }
    }
    request(action, params, subscriber) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.queueRequest(action, params);
            if (result.error) {
                throw new ApiClientError(result.error);
            }
            return this.cacheAndUpdateSubscribers(result.data, subscriber);
        });
    }
    requestV4WithCaching(method, input, subscriber) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.requestV4(method, input);
            if (response.success) {
                return this.cacheAndUpdateSubscribers(response, subscriber);
            }
            else {
                if (subscriber) {
                    subscriber.callback(response, false);
                }
                return response;
            }
        });
    }
    requestV4(method, input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const [service, _method] = method.split('.');
                return yield this.fetch.request({
                    url: '/v4/' + method,
                    body: {
                        service,
                        method: _method,
                        input,
                    },
                });
            }
            catch (err) {
                if (typeof err === 'object' &&
                    err !== null &&
                    'name' in err &&
                    'message' in err &&
                    typeof err.name === 'string' &&
                    typeof err.message === 'string') {
                    return {
                        error: {
                            name: err.name,
                            message: err.message,
                            code: 'UnknownError',
                        },
                        success: false,
                    };
                }
                else {
                    return {
                        error: {
                            name: 'UnknownError',
                            message: 'An unknown error has occurred',
                            code: 'UnknownError',
                        },
                        success: false,
                    };
                }
            }
        });
    }
    subscribeV4(method, input, callback) {
        return this._subscribe({ method, params: input, callback });
    }
    subscribe(action, params, callback) {
        if (!action.idempotent) {
            throw new Error(`${action.name} is not idempotent, cannot subscribe`);
        }
        return this._subscribe({ action, params, callback });
    }
    _subscribe(subscriber) {
        var _a;
        const name = ((_a = subscriber.action) === null || _a === void 0 ? void 0 : _a.name) || subscriber.method;
        if (!name) {
            throw Error(`Subscriber doesn't have a requester`);
        }
        const requestKey = getRequestKey({ name, params: subscriber.params });
        const cached = this.cache.get(requestKey);
        subscriber.callback(cached === null || cached === void 0 ? void 0 : cached.value, true);
        const _subscriber = Object.assign(Object.assign({}, subscriber), { requestKey });
        this.subscribers.add(_subscriber);
        this.processSubscriber(_subscriber);
        return _subscriber;
    }
    unsubscribe(subscriber) {
        this.subscribers.delete(subscriber);
    }
    subscribeChannel(channel, callback) {
        const subscriber = { channel, callback };
        this.channelSubscribers.add(subscriber);
        this.subscribeToChannels();
        return subscriber;
    }
    unsubscribeChannel(subscriber) {
        this.channelSubscribers.delete(subscriber);
        this.subscribeToChannels();
    }
    refresh(action) {
        const subscribers = Array.from(this.subscribers.values());
        const filtered = action
            ? subscribers.filter((x) => { var _a; return ((_a = x.action) === null || _a === void 0 ? void 0 : _a.name) === action || x.method === action; })
            : subscribers;
        return Promise.all(filtered.map((x) => this.processSubscriber(x)));
    }
    cacheAndUpdateSubscribers(value, subscriber) {
        const keys = new Set();
        value = this.writeResultToCache(value, keys);
        if (subscriber) {
            keys.add(subscriber.requestKey);
            this.writeCache(subscriber.requestKey, value);
            subscriber.cacheKeys = keys;
        }
        const cacheKeyArray = Array.from(keys);
        const toUpdate = Array.from(this.subscribers).filter((subscriber) => subscriber.cacheKeys &&
            cacheKeyArray.find((key) => { var _a; return (_a = subscriber.cacheKeys) === null || _a === void 0 ? void 0 : _a.has(key); }));
        for (const subscriber of toUpdate) {
            const result = this.cache.get(subscriber.requestKey);
            if (result) {
                subscriber.callback(clone(result.value), false);
            }
        }
        return value;
    }
    writeResultToCache(value, keys) {
        if (Array.isArray(value)) {
            return value.map((element) => {
                if (!(element === null || element === void 0 ? void 0 : element._id))
                    return element;
                keys.add(element._id);
                return this.writeCache(element._id, element);
            });
        }
        else if (value === null || value === void 0 ? void 0 : value._id) {
            keys.add(value._id);
            return this.writeCache(value._id, value);
        }
        else {
            return value;
        }
    }
    writeCache(key, value, overwrite) {
        const existing = this.cache.get(key);
        if (existing &&
            !overwrite &&
            !Array.isArray(value) &&
            typeof existing.value === 'object' &&
            typeof value === 'object') {
            existing.timestamp = Date.now();
            if (existing.value != null && value !== null) {
                const undefinedKeys = difference(Object.keys(existing.value), Object.keys(value));
                Object.assign(existing.value, value);
                undefinedKeys.forEach((key) => {
                    delete existing.value[key];
                });
                return existing.value;
            }
            else {
                existing.value = value;
                return value;
            }
        }
        else {
            this.cache.set(key, { timestamp: Date.now(), value });
            if (this.cache.size > CACHE_SIZE) {
                this.cache.delete(this.cache.keys().next().value);
            }
            return value;
        }
    }
    queueRequest(action, params) {
        const key = getRequestKey({ name: action.name, params });
        if (!action.idempotent || process.env.REACT_APP_NO_MULTIPLEX) {
            return this.fetch.request({
                url: `/v2/${action.name}`,
                body: params,
            });
        }
        const existing = this.requestQueue.get(key);
        if (existing) {
            return existing.deferred.promise;
        }
        const queued = {
            id: uuid(),
            action,
            params,
            deferred: defer(),
        };
        if (!this.timerHandle) {
            this.timerHandle = setTimeout(this.processQueue, 0);
        }
        this.requestQueue.set(key, queued);
        return queued.deferred.promise;
    }
    processSubscriber(subscriber) {
        return __awaiter(this, void 0, void 0, function* () {
            if (subscriber.action) {
                yield this.request(subscriber.action, subscriber.params, subscriber);
            }
            else if (subscriber.method) {
                yield this.requestV4WithCaching(subscriber.method, subscriber.params, subscriber);
            }
        });
    }
}
function getRequestKey(request) {
    return JSON.stringify({
        name: request.name,
        params: request.params,
    });
}

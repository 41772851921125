import * as Sentry from '@sentry/browser';
import { bootstrapApp } from '@getclarauk/ui';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cc279cd647f342f79a8bd627475856f0@sentry.io/1893442',

    beforeSend: (event, hint) => {
      return (hint?.originalException as any)?.noSentry ? null : event;
    },
  });
}

bootstrapApp(() => import('./GoApp'));

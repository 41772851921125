import Cookies from 'js-cookie';
const authCookieName = 'auth';
export function getAuth() {
    return (Cookies.getJSON(authCookieName) ||
        JSON.parse(localStorage.getItem(authCookieName) || '{}'));
}
export function setAuth(auth) {
    const expiresAt = Date.now() + (auth.expiresIn || 0) * 1000;
    const refreshExpiresAt = Date.now() + (auth.refreshExpiresIn || 0) * 1000;
    const data = {
        accessToken: auth.accessToken,
        refreshToken: auth.refreshToken,
        expiresAt,
        refreshExpiresAt,
    };
    Cookies.set(authCookieName, data, {
        domain: window.location.host.includes('myclara')
            ? removeSubdomain(window.location.host)
            : undefined,
        expires: new Date(refreshExpiresAt),
    });
    localStorage.setItem(authCookieName, JSON.stringify(data));
    return refreshExpiresAt;
}
export function removeAuth() {
    Cookies.remove(authCookieName, {
        domain: removeSubdomain(window.location.host),
    });
    Cookies.remove(authCookieName);
    localStorage.removeItem(authCookieName);
}
function removeSubdomain(host) {
    const match = host.match(/^.*?([^.]+\.[^.]+)$/);
    return match ? match[1] : host;
}

import { trimStart } from 'lodash';
import { isCustomType } from '../define/defineCustomType';
import { models as _models, } from '../define/defineModel';
import { getTypeConverter } from './registerTypeConverter';
export function convertModel(operation, schema, data, errors = [], path = '', models, strict) {
    const result = {};
    for (const key in schema) {
        result[key] = convertValue(operation, schema[key], data[key], errors, path + key, models, strict);
    }
    return [errors, result];
}
export function convertValue(operation, field, value, errors = [], key = '', models, strict) {
    if (value != null) {
        if (field.array) {
            if (!Array.isArray(value)) {
                errors.push({ path: key, message: 'Expected array' });
            }
            else {
                const element = isTypeDescriptor(field.type)
                    ? { type: field.type, required: true }
                    : field.type;
                return value.map((value, i) => convertValue(operation, element, value, errors, `${key}.${i}`, models, strict));
            }
        }
        else {
            const type = isTypeReference(field.type)
                ? followTypeReference(field.type, models || _models)
                : field.type;
            if (typeof field.type === 'string') {
                return convertPrimitive(key, field.type, value, errors);
            }
            else if (field.type.kind === 'CustomType') {
                return convertFieldCustomType(operation, key, isCustomType(type) ? getTypeConverter(type.name) : type, value, errors);
            }
            else if (field.type.kind === 'Model') {
                const [, result] = convertModel(operation, type.schema, value, errors, `${key}.`, models, strict);
                return result;
            }
        }
        // } else if (field.required && typeof value === 'undefined') {
        //   errors.push({ path: key, message: 'Missing required field' });
        // } else if (!field.nullable && field.required && value === null) {
        //   errors.push({ path: key, message: 'Null supplied for non-nullable field' });
    }
    else if (strict && field.required && !field.nullable && value == null) {
        errors.push({ path: key, message: 'Missing required field' });
    }
    return value;
}
export function convertOrThrow(operation, field, value, models, strict) {
    const errors = [];
    const result = convertValue(operation, field, value, errors, undefined, models, strict);
    if (errors.length) {
        throw new ConversionError(errors.map((error) => ({
            path: trimStart(error.path, '.'),
            message: error.message,
        })));
    }
    return result;
}
export class ConversionError extends Error {
    constructor(errors) {
        super('Conversion error');
        this.errors = errors;
    }
}
function convertPrimitive(path, type, value, errors) {
    if (type === 'string' && typeof value !== 'string') {
        errors.push({ path, message: 'Expected string' });
    }
    else if (type === 'number' && typeof value !== 'number') {
        errors.push({ path, message: 'Expected number' });
    }
    else if (type === 'boolean' && typeof value !== 'boolean') {
        errors.push({ path, message: 'Expected boolean' });
    }
    return value;
}
function convertFieldCustomType(operation, path, convert, value, errors) {
    try {
        return convert[operation](value);
    }
    catch (e) {
        errors.push({ path, message: e.message });
    }
    return value;
}
function isTypeReference(obj) {
    return (obj === null || obj === void 0 ? void 0 : obj.kind) && (obj.kind === 'CustomType' || obj.kind === 'Model');
}
function followTypeReference(ref, models) {
    if (ref.kind === 'Model') {
        const model = models[ref.name];
        if (!model) {
            throw new Error(`Could not find model ${ref.name}`);
        }
        return model;
    }
    else {
        return getTypeConverter(ref.name);
    }
}
function isTypeDescriptor(obj) {
    return (typeof obj === 'string' ||
        ((obj === null || obj === void 0 ? void 0 : obj.kind) && (obj.kind === 'CustomType' || obj.kind === 'Model')));
}

import { __awaiter } from "tslib";
import { useCallback, useState } from 'react';
import { useClient } from './useClient';
export function useApi(action) {
    const client = useClient();
    const [loading, setLoading] = useState(0);
    const call = useCallback((params, options) => __awaiter(this, void 0, void 0, function* () {
        if (!client) {
            throw new Error(`No client in context`);
        }
        setLoading((loading) => loading + 1);
        try {
            const result = yield client.request(action, params);
            if (options === null || options === void 0 ? void 0 : options.refresh) {
                if (options.refresh === true) {
                    yield client.refresh();
                }
                else {
                    for (const action of options.refresh) {
                        yield client.refresh(typeof action === 'string' ? action : action.name);
                    }
                }
            }
            return result;
        }
        finally {
            setLoading((loading) => loading - 1);
        }
    }), [client, action]);
    return [call, loading > 0];
}

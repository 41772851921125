export const addOrganisationAffiliateAction = {
    name: 'AddOrganisationAffiliate',
    model: 'Organisation',
};
export const addUserToPharmacyAction = {
    name: 'addUserToPharmacy',
};
export const affiliateUpdateLicenceAction = {
    name: 'affiliateUpdateLicence',
    model: 'Licence',
};
export const approveRepeatOrderAction = {
    name: 'ApproveRepeatOrder',
    model: 'RepeatOrder',
};
export const autocompleteAddressAction = {
    name: 'autocompleteAddress',
    idempotent: true,
    model: 'AutocompleteAddress',
    array: true,
};
export const createCdReportAction = {
    name: 'CreateCdReport',
    model: 'CdReport',
};
export const createDiscountVoucherAction = {
    name: 'CreateDiscountVoucher',
    model: 'DiscountVoucher',
};
export const createHealthcareProfessionalAction = {
    name: 'CreateHealthcareProfessional',
    model: 'HealthcareProfessional',
};
export const createIntegrationKeyAction = {
    name: 'CreateIntegrationKey',
    model: 'IntegrationKey',
};
export const createMhraAlertAction = {
    name: 'CreateMhraAlert',
    model: 'MhraAlert',
};
export const createPendingEntriesAction = {
    name: 'CreatePendingEntries',
};
export const createProviderAppointmentTypesAction = {
    name: 'CreateProviderAppointmentTypes',
};
export const createProviderServiceAction = {
    name: 'CreateProviderService',
    model: 'ProviderService',
};
export const createRepeatOrderV2Action = {
    name: 'createRepeatOrderV2',
    model: 'RepeatOrder',
};
export const createSupplierAction = {
    name: 'CreateSupplier',
    model: 'Supplier',
};
export const deleteCdReportAction = {
    name: 'DeleteCdReport',
};
export const deleteDiscountVoucherAction = {
    name: 'DeleteDiscountVoucher',
};
export const deleteHealthcareProfessionalAction = {
    name: 'DeleteHealthcareProfessional',
};
export const deleteMhraAlertAction = {
    name: 'DeleteMhraAlert',
};
export const deleteProviderAccountAction = {
    name: 'DeleteProviderAccount',
};
export const deleteProviderServiceAction = {
    name: 'DeleteProviderService',
};
export const deleteRpLogItemAction = {
    name: 'DeleteRpLogItem',
};
export const exportAppointmentsAction = {
    name: 'ExportAppointments',
    model: 'ExportAppointmentsResult',
};
export const finaliseInvoiceAction = {
    name: 'FinaliseInvoice',
};
export const finishSetupAction = {
    name: 'FinishSetup',
    model: 'Organisation',
};
export const getAffiliateByShortNameAction = {
    name: 'GetAffiliateByShortName',
    idempotent: true,
    model: 'Affiliate',
};
export const getCdEntriesByDrugIdV2Action = {
    name: 'getCdEntriesByDrugIdV2',
    idempotent: true,
    model: 'GetCdEntriesByDrugIdResult',
};
export const getCdReportByIdAction = {
    name: 'GetCdReportById',
    idempotent: true,
    model: 'CdReport',
};
export const getCdReportPdfUrlAction = {
    name: 'GetCdReportPdfUrl',
};
export const getCdReportsByPharmacyIdAction = {
    name: 'GetCdReportsByPharmacyId',
    idempotent: true,
    model: 'GetCdReportsByPharmacyIdResult',
};
export const getDiscountVoucherByIdAction = {
    name: 'GetDiscountVoucherById',
    idempotent: true,
    model: 'DiscountVoucher',
};
export const getDiscountVouchersByAffiliateIdAction = {
    name: 'GetDiscountVouchersByAffiliateId',
    idempotent: true,
    model: 'DiscountVoucher',
    array: true,
};
export const getDiscountVouchersByOrganisationIdAction = {
    name: 'GetDiscountVouchersByOrganisationId',
    idempotent: true,
    model: 'DiscountVoucher',
    array: true,
};
export const getHealthcareProfessionalByIdAction = {
    name: 'GetHealthcareProfessionalById',
    idempotent: true,
    model: 'HealthcareProfessional',
};
export const getHealthcareProfessionalsByPharmacyIdAction = {
    name: 'GetHealthcareProfessionalsByPharmacyId',
    idempotent: true,
    model: 'HealthcareProfessional',
    array: true,
};
export const getIntegrationKeysByOrganisationIdAction = {
    name: 'GetIntegrationKeysByOrganisationId',
    idempotent: true,
    model: 'IntegrationKey',
    array: true,
};
export const getLatestPatientMessagesByPatientIdAction = {
    name: 'GetLatestPatientMessagesByPatientId',
    idempotent: true,
    model: 'GetPatientMessagesResult',
};
export const getLatestPatientMessagesByPharmacyIdAction = {
    name: 'GetLatestPatientMessagesByPharmacyId',
    idempotent: true,
    model: 'GetPatientMessagesResult',
};
export const getMhraAlertByIdAction = {
    name: 'GetMhraAlertById',
    idempotent: true,
    model: 'MhraAlert',
};
export const getMhraAlertCountAction = {
    name: 'GetMhraAlertCount',
    idempotent: true,
};
export const getMhraAlertsByPharmacyIdAction = {
    name: 'GetMhraAlertsByPharmacyId',
    idempotent: true,
    model: 'GetMhraAlertsByPharmacyIdResult',
};
export const getMhraFeedAction = {
    name: 'GetMhraFeed',
    idempotent: true,
    model: 'MhraAlert',
    array: true,
};
export const getOrganisationsAction = {
    name: 'GetOrganisations',
    idempotent: true,
    model: 'GetOrganisationsResult',
};
export const getPatientFormResponsesByAppointmentIdAction = {
    name: 'GetPatientFormResponsesByAppointmentId',
    idempotent: true,
    model: 'PatientFormResponse',
    array: true,
};
export const getPatientMessagesByThreadIdAction = {
    name: 'GetPatientMessagesByThreadId',
    idempotent: true,
    model: 'GetPatientMessagesResult',
};
export const getPatientMessageUnreadCountAction = {
    name: 'GetPatientMessageUnreadCount',
    idempotent: true,
};
export const getProviderAccountsAction = {
    name: 'GetProviderAccounts',
    idempotent: true,
    model: 'ProviderAccount',
    array: true,
};
export const getProviderServiceByIdAction = {
    name: 'GetProviderServiceById',
    idempotent: true,
    model: 'ProviderService',
};
export const getProviderServicesByAffiliateIdAction = {
    name: 'GetProviderServicesByAffiliateId',
    idempotent: true,
    model: 'ProviderService',
    array: true,
};
export const getReferralVoucherCodeAction = {
    name: 'GetReferralVoucherCode',
    idempotent: true,
    model: 'GetReferralVoucherCodeResult',
};
export const getReferralVoucherDetailsAction = {
    name: 'GetReferralVoucherDetails',
    idempotent: true,
    model: 'GetReferralVoucherDetailsResult',
};
export const getReferralVouchersByReferrerAction = {
    name: 'GetReferralVouchersByReferrer',
    idempotent: true,
    model: 'ReferralVoucher',
    array: true,
};
export const getRepeatOrderTotalsAction = {
    name: 'GetRepeatOrderTotals',
    idempotent: true,
    model: 'GetRepeatOrderTotalsResult',
};
export const getUsersByPharmacyIdAction = {
    name: 'getUsersByPharmacyId',
    idempotent: true,
    model: 'User',
    array: true,
};
export const promotePharmacyAction = {
    name: 'PromotePharmacy',
    model: 'Organisation',
};
export const removeOrganisationAffiliateAction = {
    name: 'RemoveOrganisationAffiliate',
    model: 'Organisation',
};
export const searchHealthcareProfessionalsAction = {
    name: 'SearchHealthcareProfessionals',
    idempotent: true,
    model: 'HealthcareProfessional',
    array: true,
};
export const sendPatientMessageAction = {
    name: 'SendPatientMessage',
    model: 'PatientMessage',
};
export const sendRepeatOrderToPracticeAction = {
    name: 'SendRepeatOrderToPractice',
    model: 'RepeatOrder',
};
export const suggestRepeatOrderChangesAction = {
    name: 'SuggestRepeatOrderChanges',
    model: 'RepeatOrder',
};
export const syncOrganisationInvoiceWithXeroAction = {
    name: 'SyncOrganisationInvoiceWithXero',
    model: 'OrganisationInvoice',
};
export const updateAppointmentAction = {
    name: 'UpdateAppointment',
    model: 'Appointment',
};
export const updateDiscountVoucherAction = {
    name: 'UpdateDiscountVoucher',
    model: 'DiscountVoucher',
};
export const updateHealthcareProfessionalAction = {
    name: 'UpdateHealthcareProfessional',
    model: 'HealthcareProfessional',
};
export const updateMhraAlertAction = {
    name: 'UpdateMhraAlert',
    model: 'MhraAlert',
};
export const updatePatientMessageReadAction = {
    name: 'UpdatePatientMessageRead',
};
export const updateProviderServiceAction = {
    name: 'UpdateProviderService',
    model: 'ProviderService',
};
export const updateRepeatOrdersStatusAction = {
    name: 'updateRepeatOrdersStatus',
    model: 'RepeatOrder',
    array: true,
};
export const upsertProviderAccountAction = {
    name: 'UpsertProviderAccount',
    model: 'ProviderAccount',
};
export const affiliateSignupAction = {
    name: 'AffiliateSignup',
    model: 'AffiliateSignupResult',
};
export const authenticateAction = {
    name: 'authenticate',
    model: 'AuthenticateResult',
};
export const cancelIntegrationPendingEntriesAction = {
    name: 'cancelIntegrationPendingEntries',
};
export const changeAppointmentAction = {
    name: 'changeAppointment',
    model: 'Appointment',
};
export const chargeInvoiceAction = {
    name: 'chargeInvoice',
    model: 'OrganisationInvoice',
};
export const completeGoCardlessRedirectFlowAction = {
    name: 'completeGoCardlessRedirectFlow',
};
export const correctCdEntryAction = {
    name: 'CorrectCdEntry',
    model: 'CdEntry',
};
export const countPendingEntriesAction = {
    name: 'countPendingEntries',
    idempotent: true,
};
export const createAffiliateAction = {
    name: 'createAffiliate',
    model: 'Affiliate',
};
export const createAffiliatePaymentAction = {
    name: 'CreateAffiliatePayment',
    model: 'AffiliatePayment',
};
export const createApiKeyAction = {
    name: 'createApiKey',
    model: 'UserApiKey',
};
export const createAppointmentTypeAction = {
    name: 'createAppointmentType',
    model: 'AppointmentType',
};
export const createAppointmentV2Action = {
    name: 'createAppointmentV2',
    model: 'CreateAppointmentResult',
};
export const createCdAnnotationAction = {
    name: 'createCdAnnotation',
    model: 'CdAnnotation',
};
export const createCdEntryAction = {
    name: 'createCdEntry',
    model: 'CdEntry',
};
export const createCustomerTransactionAction = {
    name: 'createCustomerTransaction',
    model: 'CreateCustomerTransactionResult',
};
export const createDeliveryDriverAction = {
    name: 'CreateDeliveryDriver',
    model: 'DeliveryDriver',
};
export const createDestructionAction = {
    name: 'createDestruction',
    model: 'Destruction',
};
export const createDiaryAction = {
    name: 'createDiary',
    model: 'Diary',
};
export const createDrugAction = {
    name: 'createDrug',
    model: 'Drug',
};
export const createEmailAccountAction = {
    name: 'createEmailAccount',
    model: 'CreateEmailAccountResult',
};
export const createErrorLogItemAction = {
    name: 'CreateErrorLogItem',
    model: 'ErrorLogItem',
};
export const createGoCardlessRedirectFlowAction = {
    name: 'CreateGoCardlessRedirectFlow',
    model: 'CreateGoCardlessRedirectFlowResult',
};
export const createHandoverLogItemAction = {
    name: 'createHandoverLogItem',
    model: 'HandoverLogItem',
};
export const createHandoverTaskAction = {
    name: 'createHandoverTask',
    model: 'HandoverTask',
};
export const createIntegrationRequestAction = {
    name: 'createIntegrationRequest',
    model: 'IntegrationRequest',
};
export const createInvoiceItemAction = {
    name: 'CreateInvoiceItem',
    model: 'InvoiceItem',
};
export const createLabAccountAction = {
    name: 'createLabAccount',
    model: 'LabAccount',
};
export const createLabRequestAction = {
    name: 'createLabRequest',
    model: 'LabRequest',
};
export const createLicenceAction = {
    name: 'createLicence',
    model: 'Licence',
};
export const createMyClaraAppointmentAction = {
    name: 'createMyClaraAppointment',
    model: 'CreateAppointmentResult',
};
export const createMyClaraPatientAction = {
    name: 'createMyClaraPatient',
    model: 'Patient',
};
export const createMyClaraUserAction = {
    name: 'createMyClaraUser',
};
export const createOpayoTransactionAction = {
    name: 'createOpayoTransaction',
    model: 'CreateOpayoTransactionResult',
};
export const createPatientAction = {
    name: 'createPatient',
    model: 'Patient',
};
export const createPatientFormAction = {
    name: 'CreatePatientForm',
    model: 'PatientForm',
};
export const createPaymentProviderSettingsAction = {
    name: 'createPaymentProviderSettings',
    model: 'PaymentProviderSettings',
};
export const createPendingEntryAction = {
    name: 'createPendingEntry',
};
export const createPharmacyAction = {
    name: 'createPharmacy',
    model: 'Pharmacy',
};
export const createPharmacyFirstLogItemAction = {
    name: 'CreatePharmacyFirstLogItem',
    model: 'PharmacyFirstLogItem',
};
export const createPlanAction = {
    name: 'createPlan',
    model: 'Plan',
};
export const createPracticeAction = {
    name: 'createPractice',
    model: 'Practice',
};
export const createPrescriberAction = {
    name: 'createPrescriber',
    model: 'Prescriber',
};
export const createPrivatePrescriptionRegisterItemAction = {
    name: 'createPrivatePrescriptionRegisterItem',
    model: 'PrivatePrescriptionRegisterItem',
};
export const createQuickBookSessionAction = {
    name: 'createQuickBookSession',
    model: 'CreateQuickBookSessionResult',
};
export const createReturnedCdEntryAction = {
    name: 'createReturnedCdEntry',
    model: 'ReturnedCdEntry',
};
export const createRpLogItemAction = {
    name: 'createRpLogItem',
    model: 'RpLogItem',
};
export const createStaffMemberAction = {
    name: 'createStaffMember',
    model: 'StaffMember',
};
export const createStockCheckAction = {
    name: 'createStockCheck',
    model: 'StockCheck',
};
export const createSubdomainAction = {
    name: 'createSubdomain',
    model: 'Subdomain',
};
export const createTemperatureLocationAction = {
    name: 'createTemperatureLocation',
    model: 'TemperatureLocation',
};
export const createTemperatureLogItemAction = {
    name: 'createTemperatureLogItem',
    model: 'TemperatureLogItem',
};
export const createUserBillingProfileAction = {
    name: 'createUserBillingProfile',
    model: 'UserBillingProfile',
};
export const deleteAffiliatePaymentAction = {
    name: 'DeleteAffiliatePayment',
};
export const deleteApiKeyAction = {
    name: 'deleteApiKey',
};
export const deleteAppointmentTypeAction = {
    name: 'deleteAppointmentType',
    model: 'AppointmentType',
};
export const deleteDeliveryDriverAction = {
    name: 'deleteDeliveryDriver',
    model: 'DeliveryDriver',
};
export const deleteEmailAccountAction = {
    name: 'DeleteEmailAccount',
};
export const deleteHandoverLogItemAction = {
    name: 'deleteHandoverLogItem',
};
export const deleteHandoverTaskAction = {
    name: 'deleteHandoverTask',
};
export const deleteIntegrationKeyAction = {
    name: 'DeleteIntegrationKey',
};
export const deleteInvoiceItemAction = {
    name: 'DeleteInvoiceItem',
};
export const deleteLabAccountAction = {
    name: 'deleteLabAccount',
};
export const deleteLicenceAction = {
    name: 'deleteLicence',
};
export const deletePatientAction = {
    name: 'deletePatient',
};
export const deletePatientFormAction = {
    name: 'DeletePatientForm',
};
export const deletePaymentProviderSettingsAction = {
    name: 'deletePaymentProviderSettings',
};
export const deletePharmacyFirstLogItemAction = {
    name: 'DeletePharmacyFirstLogItem',
};
export const deletePracticeAction = {
    name: 'deletePractice',
    model: 'Practice',
};
export const deletePrescriberAction = {
    name: 'deletePrescriber',
    model: 'Prescriber',
};
export const deletePrivatePrescriptionRegisterItemAction = {
    name: 'deletePrivatePrescriptionRegisterItem',
};
export const deleteStaffMemberAction = {
    name: 'deleteStaffMember',
};
export const deleteSupplierAction = {
    name: 'deleteSupplier',
    model: 'Supplier',
};
export const deleteTemperatureLocationAction = {
    name: 'deleteTemperatureLocation',
};
export const deleteTemperatureLogItemAction = {
    name: 'deleteTemperatureLogItem',
};
export const dismissAllPendingEntriesAction = {
    name: 'dismissAllPendingEntries',
};
export const dismissPendingEntriesAction = {
    name: 'dismissPendingEntries',
};
export const dismissPendingEntryAction = {
    name: 'dismissPendingEntry',
};
export const findRelatedSubdomainsAction = {
    name: 'findRelatedSubdomains',
    idempotent: true,
    model: 'Subdomain',
    array: true,
};
export const generateMyClaraA5FlyerAction = {
    name: 'generateMyClaraA5Flyer',
};
export const getAddressByUdprnAction = {
    name: 'getAddressByUdprn',
    idempotent: true,
    model: 'AddressDetail',
};
export const getAffiliateByIdAction = {
    name: 'getAffiliateById',
    idempotent: true,
    model: 'Affiliate',
};
export const getAffiliateOrganisationByIdAction = {
    name: 'getAffiliateOrganisationById',
    idempotent: true,
    model: 'Organisation',
};
export const getAffiliatePaymentByIdAction = {
    name: 'GetAffiliatePaymentById',
    idempotent: true,
    model: 'AffiliatePayment',
};
export const getAffiliatePaymentReportByIdAction = {
    name: 'GetAffiliatePaymentReportById',
    idempotent: true,
    model: 'AffiliatePaymentReport',
};
export const getAffiliatePaymentReportUrlAction = {
    name: 'getAffiliatePaymentReportUrl',
    idempotent: true,
};
export const getAffiliatePaymentsByReportIdAction = {
    name: 'GetAffiliatePaymentsByReportId',
    idempotent: true,
    model: 'AffiliatePayment',
    array: true,
};
export const getAffiliatesAction = {
    name: 'getAffiliates',
    idempotent: true,
    model: 'Affiliate',
    array: true,
};
export const getAffiliateSummaryByIdAction = {
    name: 'GetAffiliateSummaryById',
    idempotent: true,
    model: 'Affiliate',
};
export const getApiKeysByUserIdAction = {
    name: 'getApiKeysByUserId',
    idempotent: true,
    model: 'UserApiKey',
    array: true,
};
export const getAppointmentByIdAction = {
    name: 'getAppointmentById',
    idempotent: true,
    model: 'Appointment',
};
export const getAppointmentRoomAction = {
    name: 'getAppointmentRoom',
    model: 'GetAppointmentRoomResult',
};
export const getAppointmentsByDateAction = {
    name: 'getAppointmentsByDate',
    idempotent: true,
    model: 'Appointment',
    array: true,
};
export const getAppointmentTypeByIdAction = {
    name: 'getAppointmentTypeById',
    idempotent: true,
    model: 'AppointmentType',
};
export const getAppointmentTypesByOrganisationIdAction = {
    name: 'getAppointmentTypesByOrganisationId',
    idempotent: true,
    model: 'AppointmentType',
    array: true,
};
export const getCdEntryByIdAction = {
    name: 'getCdEntryById',
    idempotent: true,
    model: 'CdEntry',
};
export const getCdRegisterDrugsByVmpAction = {
    name: 'getCdRegisterDrugsByVmp',
    idempotent: true,
    model: 'CdRegisterDrug',
    array: true,
};
export const getCurrentOrdersAction = {
    name: 'getCurrentOrders',
    idempotent: true,
    model: 'RepeatOrder',
    array: true,
};
export const getCustomerTransactionsByAppointmentIdAction = {
    name: 'GetCustomerTransactionsByAppointmentId',
    idempotent: true,
    model: 'CustomerTransaction',
    array: true,
};
export const getDecryptedIntegrationKeyAction = {
    name: 'GetDecryptedIntegrationKey',
    idempotent: true,
    model: 'IntegrationKey',
};
export const getDeliveryDriverByIdAction = {
    name: 'getDeliveryDriverById',
    idempotent: true,
    model: 'DeliveryDriver',
};
export const getDeliveryDriversAction = {
    name: 'getDeliveryDrivers',
    idempotent: true,
    model: 'DeliveryDriver',
    array: true,
};
export const getDestructionByIdAction = {
    name: 'getDestructionById',
    idempotent: true,
    model: 'Destruction',
};
export const getDestructionsByInspectorIdAction = {
    name: 'GetDestructionsByInspectorId',
    idempotent: true,
    model: 'GetDestructionsByInspectorIdResult',
};
export const getDestructionsByPharmacyIdAction = {
    name: 'getDestructionsByPharmacyId',
    idempotent: true,
    model: 'Destruction',
    array: true,
};
export const getDiariesByOrganisationIdAction = {
    name: 'getDiariesByOrganisationId',
    idempotent: true,
    model: 'Diary',
    array: true,
};
export const getDiariesByPharmacyIdAction = {
    name: 'getDiariesByPharmacyId',
    idempotent: true,
    model: 'Diary',
    array: true,
};
export const getDiaryByIdAction = {
    name: 'getDiaryById',
    idempotent: true,
    model: 'Diary',
};
export const getDiaryDaysAction = {
    name: 'getDiaryDays',
    idempotent: true,
};
export const getDmdDrugByIdAction = {
    name: 'GetDmdDrugById',
    idempotent: true,
    model: 'DmdDrug',
};
export const getDrugByIdAction = {
    name: 'getDrugById',
    idempotent: true,
    model: 'Drug',
};
export const getDrugsAction = {
    name: 'getDrugs',
    idempotent: true,
    model: 'Drug',
    array: true,
};
export const getEmailAccountsByPharmacyIdAction = {
    name: 'getEmailAccountsByPharmacyId',
    idempotent: true,
    model: 'EmailAccount',
    array: true,
};
export const getEmailMessageByIdAction = {
    name: 'getEmailMessageById',
    idempotent: true,
    model: 'EmailMessage',
};
export const getEmailMessagesByPharmacyIdAction = {
    name: 'getEmailMessagesByPharmacyId',
    idempotent: true,
    model: 'GetEmailMessagesByPharmacyIdResult',
};
export const getEnabledAppointmentTypesByPharmacyIdAction = {
    name: 'getEnabledAppointmentTypesByPharmacyId',
    idempotent: true,
    model: 'AppointmentType',
    array: true,
};
export const getErrorLogItemsByPharmacyIdAction = {
    name: 'GetErrorLogItemsByPharmacyId',
    idempotent: true,
    model: 'GetErrorLogItemsByPharmacyIdResult',
};
export const getFormsByAppointmentTypeIdAction = {
    name: 'getFormsByAppointmentTypeId',
    idempotent: true,
    model: 'PatientFormSummary',
    array: true,
};
export const getFreeSlotsByAppointmentTypeAction = {
    name: 'getFreeSlotsByAppointmentType',
    idempotent: true,
    model: 'FreeSlot',
    array: true,
};
export const getHandoverLogItemByIdAction = {
    name: 'GetHandoverLogItemById',
    idempotent: true,
    model: 'HandoverLogItem',
};
export const getHandoverLogItemsByPharmacyIdAction = {
    name: 'getHandoverLogItemsByPharmacyId',
    idempotent: true,
    model: 'GetHandoverLogItemsByPharmacyIdResult',
};
export const getHandoverTaskByIdAction = {
    name: 'GetHandoverTaskById',
    idempotent: true,
    model: 'HandoverTask',
};
export const getHandoverTasksByPharmacyIdAction = {
    name: 'GetHandoverTasksByPharmacyId',
    idempotent: true,
    model: 'HandoverTask',
    array: true,
};
export const getIgnoredDrugsByPharmacyIdAction = {
    name: 'getIgnoredDrugsByPharmacyId',
    idempotent: true,
    model: 'IgnoredDrug',
    array: true,
};
export const getInspectorByEmailAction = {
    name: 'GetInspectorByEmail',
    idempotent: true,
    model: 'GetInspectorByEmailResult',
};
export const getIntegrationDrugByIdAction = {
    name: 'getIntegrationDrugById',
    idempotent: true,
    model: 'IntegrationDrug',
};
export const getIntegrationDrugByIntegrationIdAction = {
    name: 'GetIntegrationDrugByIntegrationId',
    idempotent: true,
    model: 'IntegrationDrug',
};
export const getIntegrationDrugsByPharmacyIdAction = {
    name: 'getIntegrationDrugsByPharmacyId',
    idempotent: true,
    model: 'IntegrationDrug',
    array: true,
};
export const getIntegrationKeysByPharmacyIdAction = {
    name: 'getIntegrationKeysByPharmacyId',
    idempotent: true,
    model: 'IntegrationKey',
    array: true,
};
export const getIntegrationRequestsByOrganisationIdAction = {
    name: 'getIntegrationRequestsByOrganisationId',
    idempotent: true,
    model: 'IntegrationRequest',
    array: true,
};
export const getInvoicesByOrganisationIdAction = {
    name: 'getInvoicesByOrganisationId',
    idempotent: true,
    model: 'BillingInvoice',
    array: true,
};
export const getLabAccountByIdAction = {
    name: 'getLabAccountById',
    idempotent: true,
    model: 'LabAccount',
};
export const getLabAccountsByPharmacyIdAction = {
    name: 'getLabAccountsByPharmacyId',
    idempotent: true,
    model: 'LabAccount',
    array: true,
};
export const getLabRequestsByPharmacyIdAction = {
    name: 'getLabRequestsByPharmacyId',
    idempotent: true,
    model: 'GetLabRequestsByPharmacyIdResult',
};
export const getLicenceByIdAction = {
    name: 'getLicenceById',
    idempotent: true,
    model: 'Licence',
};
export const getLicencesByOrganisationIdAction = {
    name: 'getLicencesByOrganisationId',
    idempotent: true,
    model: 'Licence',
    array: true,
};
export const getMatchingPendingEntriesAction = {
    name: 'getMatchingPendingEntries',
    idempotent: true,
    model: 'GetMatchingPendingEntriesResult',
};
export const getMyClaraPatientBranchesAction = {
    name: 'getMyClaraPatientBranches',
    idempotent: true,
    model: 'MyClaraBranch',
    array: true,
};
export const getMyClaraPharmacyAction = {
    name: 'getMyClaraPharmacy',
    idempotent: true,
    model: 'MyClaraPharmacy',
};
export const getMyNotificationsAction = {
    name: 'getMyNotifications',
    idempotent: true,
    model: 'Notification',
    array: true,
};
export const getOrganisationByIdAction = {
    name: 'getOrganisationById',
    idempotent: true,
    model: 'Organisation',
};
export const getOrganisationDiscountAction = {
    name: 'GetOrganisationDiscount',
    idempotent: true,
    model: 'GetOrganisationDiscountResult',
};
export const getOrganisationInvoiceByIdAction = {
    name: 'getOrganisationInvoiceById',
    idempotent: true,
    model: 'GetOrganisationInvoiceByIdResult',
};
export const getOrganisationInvoicePdfUrlAction = {
    name: 'getOrganisationInvoicePdfUrl',
};
export const getOrganisationInvoicesAction = {
    name: 'getOrganisationInvoices',
    idempotent: true,
    model: 'GetOrganisationInvoicesResult',
};
export const getPatientByIdAction = {
    name: 'getPatientById',
    idempotent: true,
    model: 'Patient',
};
export const getPatientByUserIdAction = {
    name: 'getPatientByUserId',
    idempotent: true,
    model: 'Patient',
};
export const getPatientFormByIdAction = {
    name: 'GetPatientFormById',
    idempotent: true,
    model: 'PatientForm',
};
export const getPatientFormsByOrganisationIdAction = {
    name: 'GetPatientFormsByOrganisationId',
    idempotent: true,
    model: 'PatientForm',
    array: true,
};
export const getPatientPharmaciesAction = {
    name: 'getPatientPharmacies',
    idempotent: true,
    model: 'PatientPharmacy',
    array: true,
};
export const getPatientRecentEntriesAction = {
    name: 'getPatientRecentEntries',
    idempotent: true,
    model: 'CdEntry',
    array: true,
};
export const getPatientsAction = {
    name: 'getPatients',
    idempotent: true,
    model: 'Patient',
    array: true,
};
export const getPatientsByUserIdAction = {
    name: 'getPatientsByUserId',
    idempotent: true,
    model: 'Patient',
    array: true,
};
export const getPaymentProviderAction = {
    name: 'getPaymentProvider',
    idempotent: true,
    model: 'GetPaymentProviderResult',
};
export const getPaymentProviderSettingsByIdAction = {
    name: 'GetPaymentProviderSettingsById',
    idempotent: true,
    model: 'PaymentProviderSettings',
};
export const getPaymentProviderSettingsByOrganisationIdAction = {
    name: 'GetPaymentProviderSettingsByOrganisationId',
    idempotent: true,
    model: 'PaymentProviderSettings',
    array: true,
};
export const getPaymentReportsByAffiliateIdAction = {
    name: 'getPaymentReportsByAffiliateId',
    idempotent: true,
    model: 'GetPaymentReportsByAffiliateIdResult',
};
export const getPendingAffiliatePaymentsAction = {
    name: 'GetPendingAffiliatePayments',
    idempotent: true,
    model: 'AffiliatePayment',
    array: true,
};
export const getPendingEntriesByPatientIdAction = {
    name: 'getPendingEntriesByPatientId',
    idempotent: true,
    model: 'PendingEntry',
    array: true,
};
export const getPendingEntriesByPharmacyIdAction = {
    name: 'getPendingEntriesByPharmacyId',
    idempotent: true,
    model: 'GetPendingEntriesByPharmacyIdResult',
};
export const getPendingEntryByIdAction = {
    name: 'getPendingEntryById',
    idempotent: true,
    model: 'PendingEntry',
};
export const getPendingInvoiceItemsAction = {
    name: 'GetPendingInvoiceItems',
    idempotent: true,
    model: 'InvoiceItem',
    array: true,
};
export const getPharmaciesByOrganisationIdAction = {
    name: 'getPharmaciesByOrganisationId',
    idempotent: true,
    model: 'Pharmacy',
    array: true,
};
export const getPharmaciesByUserIdAction = {
    name: 'getPharmaciesByUserId',
    idempotent: true,
    model: 'Pharmacy',
    array: true,
};
export const getPharmaciesWithFeaturesAction = {
    name: 'getPharmaciesWithFeatures',
    idempotent: true,
    model: 'MyClaraPharmacy',
    array: true,
};
export const getPharmacyByIdAction = {
    name: 'getPharmacyById',
    idempotent: true,
    model: 'Pharmacy',
};
export const getPharmacyFirstLogItemByIdAction = {
    name: 'GetPharmacyFirstLogItemById',
    idempotent: true,
    model: 'PharmacyFirstLogItem',
};
export const getPharmacyFirstLogItemsByPharmacyIdAction = {
    name: 'GetPharmacyFirstLogItemsByPharmacyId',
    idempotent: true,
    model: 'GetPharmacyFirstLogItemsByPharmacyIdResult',
};
export const getPharmacyFirstMonthlyTotalAction = {
    name: 'GetPharmacyFirstMonthlyTotal',
    idempotent: true,
};
export const getPharmacyFirstServicesAction = {
    name: 'GetPharmacyFirstServices',
    idempotent: true,
    model: 'PharmacyFirstService',
    array: true,
};
export const getPlanByIdAction = {
    name: 'getPlanById',
    idempotent: true,
    model: 'Plan',
};
export const getPlansAction = {
    name: 'getPlans',
    idempotent: true,
    model: 'Plan',
    array: true,
};
export const getPracticeByIdAction = {
    name: 'getPracticeById',
    idempotent: true,
    model: 'Practice',
};
export const getPracticesByPharmacyIdAction = {
    name: 'getPracticesByPharmacyId',
    idempotent: true,
    model: 'Practice',
    array: true,
};
export const getPrescriberByIdAction = {
    name: 'getPrescriberById',
    idempotent: true,
    model: 'Prescriber',
};
export const getPrescribersByPharmacyIdAction = {
    name: 'getPrescribersByPharmacyId',
    idempotent: true,
    model: 'Prescriber',
    array: true,
};
export const getPrivatePrescriptionRegisterItemByIdAction = {
    name: 'GetPrivatePrescriptionRegisterItemById',
    idempotent: true,
    model: 'PrivatePrescriptionRegisterItem',
};
export const getPrivatePrescriptionRegisterItemsByPharmacyIdAction = {
    name: 'getPrivatePrescriptionRegisterItemsByPharmacyId',
    idempotent: true,
    model: 'GetPrivatePrescriptionRegisterItemsByPharmacyIdResult',
};
export const getQuickBookSessionByTokenAction = {
    name: 'GetQuickBookSessionByToken',
    idempotent: true,
    model: 'QuickBookSession',
};
export const getRecentAppointmentsByPatientIdAction = {
    name: 'getRecentAppointmentsByPatientId',
    idempotent: true,
    model: 'Appointment',
    array: true,
};
export const getRecentAppointmentsByUserIdAction = {
    name: 'getRecentAppointmentsByUserId',
    idempotent: true,
    model: 'Appointment',
    array: true,
};
export const getRecentOrdersByPatientIdAction = {
    name: 'getRecentOrdersByPatientId',
    idempotent: true,
    model: 'RepeatOrder',
    array: true,
};
export const getRecentOrdersByUserIdAction = {
    name: 'getRecentOrdersByUserId',
    idempotent: true,
    model: 'RepeatOrder',
    array: true,
};
export const getRegisterPdfsByDrugIdAction = {
    name: 'getRegisterPdfsByDrugId',
    idempotent: true,
    model: 'RegisterPdf',
    array: true,
};
export const getRegisterPdfUrlAction = {
    name: 'getRegisterPdfUrl',
};
export const getRepeatOrderByIdAction = {
    name: 'getRepeatOrderById',
    idempotent: true,
    model: 'RepeatOrder',
};
export const getRepeatOrdersByPharmacyIdAction = {
    name: 'GetRepeatOrdersByPharmacyId',
    idempotent: true,
    model: 'GetRepeatOrdersByPharmacyIdResult',
};
export const getReturnedCdEntriesByPharmacyIdAction = {
    name: 'GetReturnedCdEntriesByPharmacyId',
    idempotent: true,
    model: 'GetReturnedCdEntriesByPharmacyIdResult',
};
export const getRpLogItemByIdAction = {
    name: 'getRpLogItemById',
    idempotent: true,
    model: 'RpLogItem',
};
export const getRpLogItemsByPharmacyIdAction = {
    name: 'getRpLogItemsByPharmacyId',
    idempotent: true,
    model: 'GetRpLogItemsByPharmacyIdResult',
};
export const getSignatureRequestDetailsAction = {
    name: 'GetSignatureRequestDetails',
    idempotent: true,
    model: 'SignatureRequestDetails',
};
export const getStaffMemberByIdAction = {
    name: 'GetStaffMemberById',
    idempotent: true,
    model: 'StaffMember',
};
export const getStaffMembersByOrganisationIdAction = {
    name: 'getStaffMembersByOrganisationId',
    idempotent: true,
    model: 'StaffMember',
    array: true,
};
export const getStockCheckByIdAction = {
    name: 'getStockCheckById',
    idempotent: true,
    model: 'StockCheck',
};
export const getStockChecksByPharmacyIdAction = {
    name: 'getStockChecksByPharmacyId',
    idempotent: true,
    model: 'StockCheck',
    array: true,
};
export const getSupplierByIdAction = {
    name: 'getSupplierById',
    idempotent: true,
    model: 'Supplier',
};
export const getSuppliersByPharmacyIdAction = {
    name: 'getSuppliersByPharmacyId',
    idempotent: true,
    model: 'Supplier',
    array: true,
};
export const getTemperatureLocationByIdAction = {
    name: 'GetTemperatureLocationById',
    idempotent: true,
    model: 'TemperatureLocation',
};
export const getTemperatureLocationsByPharmacyIdAction = {
    name: 'GetTemperatureLocationsByPharmacyId',
    idempotent: true,
    model: 'TemperatureLocation',
    array: true,
};
export const getTemperatureLogItemByIdAction = {
    name: 'GetTemperatureLogItemById',
    idempotent: true,
    model: 'TemperatureLogItem',
};
export const getTemperatureLogItemsByPharmacyIdAction = {
    name: 'getTemperatureLogItemsByPharmacyId',
    idempotent: true,
    model: 'GetTemperatureLogItemsByPharmacyIdResult',
};
export const getTodaysPendingEntriesAction = {
    name: 'getTodaysPendingEntries',
    idempotent: true,
    model: 'PendingEntry',
    array: true,
};
export const getTodaysPendingEntriesV2Action = {
    name: 'getTodaysPendingEntriesV2',
    idempotent: true,
    model: 'GetPendingEntriesByPharmacyIdResult',
};
export const getUserBillingProfileByIdAction = {
    name: 'getUserBillingProfileById',
    idempotent: true,
    model: 'UserBillingProfile',
};
export const getUserBillingProfilesAction = {
    name: 'getUserBillingProfiles',
    idempotent: true,
    model: 'UserBillingProfile',
    array: true,
};
export const getUserByIdAction = {
    name: 'getUserById',
    idempotent: true,
    model: 'User',
};
export const getUserNotificationsByUserIdAction = {
    name: 'getUserNotificationsByUserId',
    idempotent: true,
    model: 'GetUserNotificationsByUserIdResult',
};
export const getUsersByAffiliateIdAction = {
    name: 'getUsersByAffiliateId',
    idempotent: true,
    model: 'GetUsersByAffiliateIdResult',
};
export const getUsersByOrganisationIdAction = {
    name: 'getUsersByOrganisationId',
    idempotent: true,
    model: 'User',
    array: true,
};
export const inspectorGetDestructionByIdAction = {
    name: 'InspectorGetDestructionById',
    idempotent: true,
    model: 'Destruction',
};
export const inviteAffiliateUserAction = {
    name: 'inviteAffiliateUser',
};
export const invitePharmacyUserAction = {
    name: 'invitePharmacyUser',
};
export const lookupSubdomainAction = {
    name: 'lookupSubdomain',
    idempotent: true,
    model: 'SubdomainDetails',
};
export const makePendingInstalmentsAction = {
    name: 'makePendingInstalments',
};
export const markNotificationsAsSeenAction = {
    name: 'markNotificationsAsSeen',
};
export const markUserNotificationAsSeenAction = {
    name: 'markUserNotificationAsSeen',
    model: 'UserNotification',
};
export const needsIntegrationSetupAction = {
    name: 'needsIntegrationSetup',
    idempotent: true,
};
export const notifyEveryoneAction = {
    name: 'notifyEveryone',
};
export const quickBookAction = {
    name: 'quickBook',
    model: 'CreateAppointmentResult',
};
export const refreshAccessTokenAction = {
    name: 'refreshAccessToken',
    model: 'AuthenticateResult',
};
export const regenerateAffiliateReportAction = {
    name: 'RegenerateAffiliateReport',
};
export const regenerateAffiliateSecretKeyAction = {
    name: 'RegenerateAffiliateSecretKey',
    model: 'RegenerateAffiliateSecretResult',
};
export const regenerateAffiliateWebhookSecretAction = {
    name: 'RegenerateAffiliateWebhookSecret',
    model: 'RegenerateAffiliateSecretResult',
};
export const removeAffiliateFromOrganisationAction = {
    name: 'removeAffiliateFromOrganisation',
};
export const requestRegisterPdfAction = {
    name: 'requestRegisterPdf',
};
export const resendEmailMessageAction = {
    name: 'resendEmailMessage',
};
export const resetPasswordAction = {
    name: 'resetPassword',
    model: 'ResetPasswordResult',
};
export const searchAllPharmaciesAction = {
    name: 'searchAllPharmacies',
    idempotent: true,
    model: 'Pharmacy',
    array: true,
};
export const searchCdRegisterDrugsAction = {
    name: 'searchCdRegisterDrugs',
    idempotent: true,
    model: 'CdRegisterDrug',
    array: true,
};
export const searchDrugDbAction = {
    name: 'searchDrugDb',
    idempotent: true,
    model: 'DmdDrug',
    array: true,
};
export const searchLabProductsAction = {
    name: 'searchLabProducts',
    idempotent: true,
    model: 'LabProduct',
    array: true,
};
export const searchPrescribersAction = {
    name: 'searchPrescribers',
    idempotent: true,
    model: 'Prescriber',
    array: true,
};
export const searchSuppliersAction = {
    name: 'searchSuppliers',
    idempotent: true,
    model: 'Supplier',
    array: true,
};
export const sendEmailVerificationAction = {
    name: 'SendEmailVerification',
};
export const sendPasswordRecoveryAction = {
    name: 'sendPasswordRecovery',
};
export const signupAction = {
    name: 'signup',
};
export const signupInspectorAction = {
    name: 'SignupInspector',
};
export const sumPendingEntriesAction = {
    name: 'sumPendingEntries',
    idempotent: true,
    model: 'SumPendingEntriesResult',
};
export const updateAffiliateAction = {
    name: 'updateAffiliate',
    model: 'Affiliate',
};
export const updateAffiliatePaymentAction = {
    name: 'UpdateAffiliatePayment',
    model: 'AffiliatePayment',
};
export const updateAffiliateSummaryAction = {
    name: 'UpdateAffiliateSummary',
    model: 'Affiliate',
};
export const updateAppointmentTypeAction = {
    name: 'updateAppointmentType',
    model: 'AppointmentType',
};
export const updateCustomerTransactionAction = {
    name: 'updateCustomerTransaction',
};
export const updateDeliveryDriverAction = {
    name: 'updateDeliveryDriver',
    model: 'DeliveryDriver',
};
export const updateDiaryAction = {
    name: 'updateDiary',
    model: 'Diary',
};
export const updateDiaryBlockedTimeAction = {
    name: 'updateDiaryBlockedTime',
};
export const updateDrugAction = {
    name: 'updateDrug',
    model: 'UpdateDrugResult',
};
export const updateDrugFlagsAction = {
    name: 'updateDrugFlags',
    model: 'Drug',
};
export const updateEmailAccountAction = {
    name: 'updateEmailAccount',
    model: 'EmailAccount',
};
export const updateHandoverLogItemAction = {
    name: 'updateHandoverLogItem',
    model: 'HandoverLogItem',
};
export const updateHandoverTaskAction = {
    name: 'updateHandoverTask',
    model: 'HandoverTask',
};
export const updateIntegrationDrugAction = {
    name: 'updateIntegrationDrug',
    model: 'IntegrationDrug',
};
export const updateIntegrationPendingEntriesAction = {
    name: 'updateIntegrationPendingEntries',
    model: 'PendingEntry',
    array: true,
};
export const updateInvoiceItemAction = {
    name: 'UpdateInvoiceItem',
    model: 'InvoiceItem',
};
export const updateLabAccountAction = {
    name: 'updateLabAccount',
    model: 'LabAccount',
};
export const updateLicenceAction = {
    name: 'updateLicence',
    model: 'Licence',
};
export const updateMyClaraPatientAction = {
    name: 'updateMyClaraPatient',
    model: 'Patient',
};
export const updateOrganisationAction = {
    name: 'updateOrganisation',
    model: 'Organisation',
};
export const updateOrganisationInvoiceAction = {
    name: 'updateOrganisationInvoice',
    model: 'OrganisationInvoice',
};
export const updatePatientAction = {
    name: 'updatePatient',
    model: 'Patient',
};
export const updatePatientFormAction = {
    name: 'UpdatePatientForm',
    model: 'PatientForm',
};
export const updatePaymentProviderSettingsAction = {
    name: 'UpdatePaymentProviderSettings',
    model: 'PaymentProviderSettings',
};
export const updatePharmacyAction = {
    name: 'updatePharmacy',
    model: 'Pharmacy',
};
export const updatePharmacyFirstLogItemAction = {
    name: 'UpdatePharmacyFirstLogItem',
    model: 'PharmacyFirstLogItem',
};
export const updatePlanAction = {
    name: 'updatePlan',
    model: 'Plan',
};
export const updatePracticeAction = {
    name: 'updatePractice',
    model: 'Practice',
};
export const updatePrescriberAction = {
    name: 'updatePrescriber',
    model: 'Prescriber',
};
export const updatePrivatePrescriptionRegisterItemAction = {
    name: 'updatePrivatePrescriptionRegisterItem',
    model: 'PrivatePrescriptionRegisterItem',
};
export const updateRepeatOrderAction = {
    name: 'updateRepeatOrder',
    model: 'RepeatOrder',
};
export const updateReturnedCdEntryBatchAction = {
    name: 'updateReturnedCdEntryBatch',
};
export const updateRpLogItemAction = {
    name: 'updateRpLogItem',
    model: 'RpLogItem',
};
export const updateStaffMemberAction = {
    name: 'UpdateStaffMember',
    model: 'StaffMember',
};
export const updateSubscriptionAction = {
    name: 'updateSubscription',
    model: 'UpdateSubscriptionResult',
};
export const updateSupplierAction = {
    name: 'updateSupplier',
    model: 'Supplier',
};
export const updateTemperatureLocationAction = {
    name: 'updateTemperatureLocation',
    model: 'TemperatureLocation',
};
export const updateTemperatureLogItemAction = {
    name: 'updateTemperatureLogItem',
    model: 'TemperatureLogItem',
};
export const updateUserAction = {
    name: 'updateUser',
    model: 'User',
};
export const updateUserBillingProfileAction = {
    name: 'updateUserBillingProfile',
    model: 'UserBillingProfile',
};
export const updateUserPasswordAction = {
    name: 'updateUserPassword',
};
export const verifyDestructionAction = {
    name: 'VerifyDestruction',
    model: 'Destruction',
};
export const verifyEmailAction = {
    name: 'VerifyEmail',
};
export const verifySignatureRequestAction = {
    name: 'VerifySignatureRequest',
};
export var AffiliateAccountType;
(function (AffiliateAccountType) {
    AffiliateAccountType["Marketing"] = "marketing";
    AffiliateAccountType["Reseller"] = "reseller";
    AffiliateAccountType["ServiceProvider"] = "service-provider";
})(AffiliateAccountType || (AffiliateAccountType = {}));
export var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["Cancelled"] = "cancelled";
    AppointmentStatus["Attended"] = "attended";
    AppointmentStatus["DidNotAttend"] = "dna";
})(AppointmentStatus || (AppointmentStatus = {}));
export var CdEntryCollectedByType;
(function (CdEntryCollectedByType) {
    CdEntryCollectedByType["CollectedByPatient"] = "patient";
    CdEntryCollectedByType["CollectedByOther"] = "other";
    CdEntryCollectedByType["Delivered"] = "delivery";
    CdEntryCollectedByType["CollectedByHealthcareProfessional"] = "healthcareprofessional";
})(CdEntryCollectedByType || (CdEntryCollectedByType = {}));
export var CdEntryType;
(function (CdEntryType) {
    CdEntryType["Correction"] = "correction";
    CdEntryType["Destruction"] = "destruction";
    CdEntryType["EditDrug"] = "editDrug";
    CdEntryType["Received"] = "received";
    CdEntryType["StockCheck"] = "stockcheck";
    CdEntryType["Supplied"] = "supplied";
})(CdEntryType || (CdEntryType = {}));
export var CustomerTransactionItemType;
(function (CustomerTransactionItemType) {
    CustomerTransactionItemType["Appointment"] = "appointment";
})(CustomerTransactionItemType || (CustomerTransactionItemType = {}));
export var CustomerTransactionStatus;
(function (CustomerTransactionStatus) {
    CustomerTransactionStatus["Pending"] = "pending";
    CustomerTransactionStatus["AcsRequired"] = "acsRequired";
    CustomerTransactionStatus["Completed"] = "completed";
    CustomerTransactionStatus["Error"] = "error";
    CustomerTransactionStatus["Failed"] = "failed";
})(CustomerTransactionStatus || (CustomerTransactionStatus = {}));
export var EmailMessageStatus;
(function (EmailMessageStatus) {
    EmailMessageStatus["Pending"] = "pending";
    EmailMessageStatus["Sending"] = "sending";
    EmailMessageStatus["Sent"] = "sent";
    EmailMessageStatus["Error"] = "error";
})(EmailMessageStatus || (EmailMessageStatus = {}));
export var ErrorLogItemCategory;
(function (ErrorLogItemCategory) {
    ErrorLogItemCategory["Label"] = "label";
    ErrorLogItemCategory["Selection"] = "selection";
    ErrorLogItemCategory["Other"] = "other";
})(ErrorLogItemCategory || (ErrorLogItemCategory = {}));
export var ErrorLogItemType;
(function (ErrorLogItemType) {
    ErrorLogItemType["OutOfDate"] = "out of date";
    ErrorLogItemType["ExtraItem"] = "extra item";
    ErrorLogItemType["MissingItem"] = "missing item";
    ErrorLogItemType["TransposedLabels"] = "transposed labels";
    ErrorLogItemType["PatientName"] = "patient name";
    ErrorLogItemType["PatientAddress"] = "patient address";
    ErrorLogItemType["Drug"] = "drug";
    ErrorLogItemType["Brand"] = "brand";
    ErrorLogItemType["Form"] = "form";
    ErrorLogItemType["Quantity"] = "quantity";
    ErrorLogItemType["Strength"] = "strength";
    ErrorLogItemType["Dose"] = "dose";
    ErrorLogItemType["Other"] = "other";
})(ErrorLogItemType || (ErrorLogItemType = {}));
export var HealthcareSystem;
(function (HealthcareSystem) {
    HealthcareSystem["Other"] = "Other";
    HealthcareSystem["Scotland"] = "Scotland";
    HealthcareSystem["NorthernIreland"] = "Northern Ireland";
    HealthcareSystem["Wales"] = "Wales";
    HealthcareSystem["England"] = "England";
})(HealthcareSystem || (HealthcareSystem = {}));
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["Due"] = "due";
    InvoiceStatus["Overdue"] = "overdue";
    InvoiceStatus["PaymentFailed"] = "payment failed";
    InvoiceStatus["Paid"] = "paid";
    InvoiceStatus["Pending"] = "pending";
})(InvoiceStatus || (InvoiceStatus = {}));
export var LogMessageType;
(function (LogMessageType) {
    LogMessageType["Info"] = "info";
    LogMessageType["Success"] = "success";
    LogMessageType["Error"] = "error";
})(LogMessageType || (LogMessageType = {}));
export var MhraAlertStatus;
(function (MhraAlertStatus) {
    MhraAlertStatus["New"] = "new";
    MhraAlertStatus["Acknowledged"] = "acknowledged";
    MhraAlertStatus["Updated"] = "updated";
})(MhraAlertStatus || (MhraAlertStatus = {}));
export var OrganisationPaymentStatus;
(function (OrganisationPaymentStatus) {
    OrganisationPaymentStatus["Trial"] = "trial";
    OrganisationPaymentStatus["Active"] = "active";
    OrganisationPaymentStatus["PaymentFailure"] = "payment failure";
    OrganisationPaymentStatus["Cancelled"] = "cancelled";
})(OrganisationPaymentStatus || (OrganisationPaymentStatus = {}));
export var PatientMessageFromType;
(function (PatientMessageFromType) {
    PatientMessageFromType["Patient"] = "patient";
    PatientMessageFromType["Pharmacy"] = "pharmacy";
})(PatientMessageFromType || (PatientMessageFromType = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Success"] = "success";
    PaymentStatus["Fail"] = "fail";
})(PaymentStatus || (PaymentStatus = {}));
export var PharmacyFirstServiceType;
(function (PharmacyFirstServiceType) {
    PharmacyFirstServiceType["Consultation"] = "consultation";
    PharmacyFirstServiceType["Prescription"] = "prescription";
    PharmacyFirstServiceType["Referral"] = "referral";
})(PharmacyFirstServiceType || (PharmacyFirstServiceType = {}));
import { flatMap } from 'lodash';
const retiredProducts = [
    'cdRegister',
    'compliance-standard',
    'compliance-plus',
    'appointments',
    'appointments-standard',
    'appointments-plus',
    'prescriptions',
    'prescriptions-standard',
    'labs-standard',
    'labs-plus',
    'pslIntegration',
    'reporting',
];
const products = [
    'cd-register',
    'logs',
    'appointments-v2',
    'myclara',
    'passenger-locator',
    'cd-reporting',
    'appointments-reporting',
    'prescriptions-v2',
    'patient-messaging',
    'labs',
    ...retiredProducts,
];
export const productMap = {
    'cd-register': ['cdRegister', 'cdRegisterIntegration', 'cdRegisterFiltering'],
    logs: ['logs'],
    'appointments-v2': [
        'appointments',
        'appointmentPayments',
        'appointmentForms',
    ],
    myclara: ['myClara'],
    'passenger-locator': ['passengerLocator'],
    'cd-reporting': ['cdReporting'],
    'appointments-reporting': ['appointmentReporting'],
    'prescriptions-v2': ['prescriptions', 'prescriptionApproval'],
    'patient-messaging': ['patientMessaging'],
    labs: ['labs'],
    cdRegister: ['cdRegister'],
    'compliance-standard': [
        'cdRegister',
        'cdRegisterFiltering',
        'cdRegisterIntegration',
    ],
    'compliance-plus': ['logs'],
    prescriptions: ['prescriptions', 'myClara'],
    appointments: ['appointments', 'appointmentPayments', 'myClara'],
    'appointments-standard': ['appointments', 'patientMessaging', 'myClara'],
    'appointments-plus': ['appointmentPayments', 'myClara', 'appointmentForms'],
    'prescriptions-standard': [
        'prescriptions',
        'patientMessaging',
        'prescriptionApproval',
        'myClara',
    ],
    'labs-standard': ['labs'],
    'labs-plus': ['labs'],
    pslIntegration: ['cdRegisterIntegration'],
    reporting: ['logs'],
};
export function productNamesToFeatureNames(products) {
    return flatMap(products, (product) => productMap[product] || product);
}
export var QuestionType;
(function (QuestionType) {
    QuestionType["Text"] = "text";
    QuestionType["MultiLineText"] = "multilinetext";
    QuestionType["Checkbox"] = "checkbox";
    QuestionType["Select"] = "select";
    QuestionType["MultiSelect"] = "multiselect";
    QuestionType["Integer"] = "integer";
    QuestionType["Date"] = "date";
    QuestionType["Email"] = "email";
})(QuestionType || (QuestionType = {}));
export var RepeatOrderStatus;
(function (RepeatOrderStatus) {
    RepeatOrderStatus["New"] = "new";
    RepeatOrderStatus["Approved"] = "approved";
    RepeatOrderStatus["ChangeSuggested"] = "change-suggested";
    RepeatOrderStatus["SubmissionPending"] = "submission-pending";
    RepeatOrderStatus["SubmissionFailed"] = "submission-failed";
    RepeatOrderStatus["Processed"] = "processed";
    RepeatOrderStatus["WaitingPayment"] = "waiting-payment";
    RepeatOrderStatus["Dispensed"] = "dispensed";
    RepeatOrderStatus["Cancelled"] = "cancelled";
})(RepeatOrderStatus || (RepeatOrderStatus = {}));
export var RepeatOrderStatusFilter;
(function (RepeatOrderStatusFilter) {
    RepeatOrderStatusFilter["All"] = "all";
    RepeatOrderStatusFilter["Active"] = "active";
    RepeatOrderStatusFilter["Inactive"] = "inactive";
})(RepeatOrderStatusFilter || (RepeatOrderStatusFilter = {}));
export var ServiceIntegration;
(function (ServiceIntegration) {
    ServiceIntegration["Agilis"] = "agilis";
    ServiceIntegration["VitaJab"] = "vitajab";
    ServiceIntegration["Deltera"] = "deltera";
})(ServiceIntegration || (ServiceIntegration = {}));
export var StockCheckState;
(function (StockCheckState) {
    StockCheckState["Skipped"] = "skipped";
    StockCheckState["Correct"] = "correct";
    StockCheckState["Incorrect"] = "incorrect";
})(StockCheckState || (StockCheckState = {}));
export var TemperatureLocationType;
(function (TemperatureLocationType) {
    TemperatureLocationType["Fridge"] = "fridge";
    TemperatureLocationType["Room"] = "room";
})(TemperatureLocationType || (TemperatureLocationType = {}));
